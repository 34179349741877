import React, { useState } from "react";

import { useTranslation } from "hooks";
import BookingFlowLoadingIndicator from "BookingFlow/components/BookingFlowLoadingIndicator";
import { ReasonForStay } from ".";

export default function ChangeReasonForStay() {
  const { t } = useTranslation();

  const [reasonForStayValues, setReasonForStayValues] = useState({
    vacation: false,
    familyVacation: false,
    staycation: false,
    sightseeing: false,
    specialEvent: false,
    birthday: false,
    anniversary: false,
    wedding: false,
    honeymoon: false,
    babymoon: false,
    conference: false,
    tradeShow: false,
    businessMeeting: false,
    workcation: false,
    corporateRetreats: false,
    specialNotes: "",
  });

  const updateReasonForStayValues = (evt) => {
    const { name, checked } = evt.target;

    const checkedCount = Object.values(reasonForStayValues).filter(
      (value) => value === true
    ).length;

    // Only 3 checkboxes can be checked at a time
    if ((checked && checkedCount < 3) || !checked) {
      setReasonForStayValues((prev) => {
        return {
          ...prev,
          [name]: checked,
        };
      });
    }
  };

  const isLoading = false;
  return (
    <>
      {isLoading && <BookingFlowLoadingIndicator />}
      {!isLoading && (
        <div className="change-detail-modal">
          <div className="change-detail-modal__header">
            <div className="change-detail-modal__title">
              {t("Reason For Stay")}
            </div>
            <p className="change-detail-modal__copy">
              {t(
                "To better personalize your experience, let us know the occasion for your stay."
              )}
            </p>
          </div>
          <ReasonForStay
            reasonForStayValues={reasonForStayValues}
            onChange={updateReasonForStayValues}
            onSubmit={() => {
              console.log("Update reasons for stay for real");
            }}
          />
        </div>
      )}
    </>
  );
}
