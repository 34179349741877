import React from "react";
import { parse, format } from "date-fns";
import * as dateFnLocales from "date-fns/locale";
import get from "lodash/fp/get";

import { useTranslation } from "hooks";
import { getDateFnsLocale } from "config/languages";
import { formatArrivalTime } from "utils/utils";
import { getDefaultCheckInTime } from "utils";
import { authenticationRoutes, SignInModal } from "Authentication";
import { Link } from "react-router-dom";

function DateText({ type = "checkin", date, time }) {
  const { t, locale } = useTranslation();

  const label = type === "checkin" ? "Check In" : "Check Out";
  const dateLabel = format(
    parse(date, "yyyy-MM-dd", new Date()),
    "eee, MMM dd",
    { locale: dateFnLocales[getDateFnsLocale(locale)] }
  );

  return (
    <div className={`fs-trip__details__dates--${type}`}>
      <div className="fs-trip__details__date-label">{t(label)}</div>
      <div className="fs-trip__details__date">
        <div>{dateLabel}</div>
        {time && <div>{time}</div>}
      </div>
    </div>
  );
}

export default function TripDetails({
  canChangeDates = false,
  upcomingTrip,
  propertyContent,
  onClick,
  isUnauthenticated,
}) {
  const { t, locale } = useTranslation();

  const checkInTime =
    formatArrivalTime(
      get(["hotelProducts", "0", "checkInTime"], upcomingTrip)
    ) || getDefaultCheckInTime(propertyContent);

  return (
    <div className="fs-trip__details">
      <div className="fs-trip__details__dates">
        <DateText date={upcomingTrip.startDate} time={checkInTime} />
        <DateText type="checkout" date={upcomingTrip.endDate} />
        <div className="fs-trip__details__change-dates">
          {canChangeDates && onClick && (
            <SignInModal
              id="changeDates"
              buttonLabel={t("Change Dates")}
              type="CTA"
              kind="link"
              onClick={onClick}
              showToastOnNextView
            />
          )}
          {isUnauthenticated && (
            <Link
              to={{
                pathname: authenticationRoutes.signIn.to({
                  locale,
                }),
                state: { afterSignInRedirectTo: window.location.href },
              }}
              className="fs-toggle-button--light fs-toggle-button-light--edit"
              aria-label="Sign In"
            >
              {t("Sign in to edit arrival time")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
