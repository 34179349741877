import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "hooks";
import { selectHasOwnedProperties } from "store/profile";
import { selectIsEmployeeProfile } from "store/employeeProfile";
import * as profileRoutes from "Profile/profileRoutes";
import { getCardDisplayStatus } from "Profile/utils/membershipCardStatus";
import { selectUpcomingTripsOnly } from "store/bookingHistory/bookingHistory.selectors";
import {
  selectMemberDetails,
  selectHasMemberDetails,
} from "store/memberDetails";
import env from "config/env";
import ProfileNavigationItem from "./ProfileNavigationItem";
import useNavigation from "./EditProfileView/hooks/useNavigation";

const { STATIC_SITE_URL } = env;

function ProfileNavigation() {
  const { t, locale } = useTranslation();

  const hasOwnedProperties = useSelector(selectHasOwnedProperties);
  const upcomingTrips = useSelector(selectUpcomingTripsOnly);
  const isEmployee = useSelector(selectIsEmployeeProfile);
  let hasCardsToDisplay = false;
  const hasMemberDetails = useSelector(selectHasMemberDetails);
  const memberDetails = useSelector(selectMemberDetails);
  if (hasMemberDetails) {
    const cardsToDisplay = memberDetails.Memberships.filter((membership) => {
      return getCardDisplayStatus(membership);
    });
    if (cardsToDisplay.length > 0) {
      hasCardsToDisplay = true;
    }
  }

  const navigations = [
    {
      key: "overview",
      hash: "",
      to: [STATIC_SITE_URL, locale !== "en" && locale, "profile"]
        .filter(Boolean)
        .join("/"),
      label: "Overview",
      selectable: false,
    },

    {
      key: "profile",
      hash: "",
      label: "Profile",
      selectable: false,
    },

    upcomingTrips.length > 0 && {
      key: "upcoming-trips",
      hash: "upcoming-trips",
      label: "My Stays",
    },

    hasCardsToDisplay && {
      key: "your-memberships",
      hash: "your-memberships",
      label: "Memberships",
    },

    hasOwnedProperties && {
      key: "your-residence",
      hash: "your-residence",
      label: "Your Residence",
    },

    {
      key: "your-preferences",
      hash: "your-preferences",
      label: "Your Preferences",
    },

    {
      key: "your-interests",
      hash: "your-interests",
      label: "Your Interests",
    },

    {
      key: "request-an-invoice",
      hash: "request-an-invoice",
      label: "Request an Invoice",
    },

    isEmployee && {
      key: "employee",
      label: "Employee Travel",
      pathname: profileRoutes.employeeProfilePath.to({ locale }),
      altSelectPath: [profileRoutes.employeeStayHistoryPath.to({ locale })],
    },
  ].filter(Boolean);

  const { scrolledToSection, scrollToSection } = useNavigation({
    navigations: navigations.filter(({ hash }) => !!hash),
    topAdjust: 106,
  });
  const location = useLocation();

  return (
    <div className="Secondary-Navigation-bar">
      <ul className="Secondary-Navigation-links">
        {navigations.map(
          ({
            key,
            to,
            hash,
            label,
            selectable,
            pathname = profileRoutes.profilePath.to({ locale }),
            altSelectPath = [],
          }) => (
            <ProfileNavigationItem
              key={key}
              to={
                to || {
                  pathname,
                  hash,
                }
              }
              onClick={
                hash
                  ? (evt) => {
                      evt.preventDefault();
                      scrollToSection(hash);
                    }
                  : undefined
              }
              isSelected={
                selectable !== false &&
                (pathname === location.pathname ||
                  altSelectPath.includes(location.pathname)) &&
                (!hash || hash === scrolledToSection)
              }
            >
              {t(label)}
            </ProfileNavigationItem>
          )
        )}
      </ul>
    </div>
  );
}

export default ProfileNavigation;
