import React from "react";
import { useForm, Controller } from "react-hook-form";

import { useTranslation } from "hooks";
import { ArrivalTimeSelects, Button } from "Profile/components";
import getInitialArrivalTime from "utils/getInitialArrivalTime";
import { formatArrivalTime } from "utils/utils";

export default function ChangeETA({
  upcomingTrip,
  propertyContent,
  changeArrivalTime,
  goBackHandler,
}) {
  const { t } = useTranslation();

  const initialArrivalTime =
    getInitialArrivalTime(propertyContent)(upcomingTrip);

  const checkInTimeFormatted = formatArrivalTime(initialArrivalTime);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      arrivalTime: initialArrivalTime,
    },
  });

  const onSubmit = ({ arrivalTime }) => {
    changeArrivalTime({ arrivalTime });
    goBackHandler();
  };

  return (
    <div className="change-detail-modal">
      <div className="change-detail-modal__header">
        <div className="change-detail-modal__title">{t("Arrival Time")}</div>
        <p className="change-detail-modal__copy">
          {t("To prepare your room, what is your estimated time of arrival?")}
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form-gray change-detail-modal__form"
      >
        <Controller
          name="arrivalTime"
          control={control}
          as={ArrivalTimeSelects}
          labelAll={[t("Hour"), t("Min"), t("AM/PM")]}
          disabled={false}
          rules={{ required: true }}
        />
        <Button
          type="submit"
          className="CTA--secondary--dark"
          aria-label={t("Set {0} arrival time", [checkInTimeFormatted])}
        >
          {t("Update")}
        </Button>
      </form>
    </div>
  );
}
