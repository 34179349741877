import React from "react";

import { useTranslation } from "hooks";
import { SignInModal } from "Authentication";

export default function ArrivalDetailCard({ icon, name, value, onClick }) {
  const { t } = useTranslation();
  return (
    <div className="fs-trip__arrival-details__card">
      <img
        src={icon}
        alt={name}
        className="fs-trip__arrival-details__card__icon"
      />
      <div className="fs-trip__arrival-details__card__title">{t(name)}</div>
      <div className="fs-trip__arrival-details__card__detail">{t(value)}</div>
      {!value ? (
        <SignInModal
          id={`add__${name}`}
          className="fs-trip__arrival-details__card__add-btn"
          buttonLabel={t("Add")}
          type="CTA"
          kind="primary"
          onClick={onClick}
          showToastOnNextView
        />
      ) : (
        <SignInModal
          id={`edit__${name}`}
          className="fs-trip__arrival-details__card__edit-btn"
          buttonLabel={t("Edit")}
          type="CTA"
          kind="secondary"
          onClick={onClick}
          showToastOnNextView
        />
      )}
    </div>
  );
}
