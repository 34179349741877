import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

function ProfileNavigationItem({ to, children, isSelected, ...props }) {
  if (typeof to === "string" && to.startsWith("http")) {
    return (
      <li className="Secondary-Navigation-item">
        <a
          href={to}
          className={classNames("CTA CTA--text--dark", {
            "is-selected": isSelected,
          })}
        >
          {children}
        </a>
      </li>
    );
  }

  return (
    <li className="Secondary-Navigation-item">
      <Link
        to={to}
        className={classNames("CTA CTA--text--dark", {
          "is-selected": isSelected,
        })}
        {...props}
      >
        {children}
      </Link>
    </li>
  );
}

export default ProfileNavigationItem;
