import React, { useState } from "react";

import { useTranslation } from "hooks";
import getInitialArrivalTime from "utils/getInitialArrivalTime";
import { BookingFlowLoadingIndicator, Modal } from "BookingFlow/components";
import { formatArrivalTime } from "utils/utils";
import {
  TimeOfArrival,
  GuestInformation,
  ReasonForStay,
  ChangeETA,
  ChangeReasonForStay,
  ChangeGuestInfo,
} from "./ArrivalDetailsComponents";
import ArrivalDetailCard from "./ArrivalDetailsComponents/ArrivalDetailCard";

export default function ArrivalDetails({
  upcomingTrip,
  propertyContent,

  changeArrivalTime,
}) {
  const { t } = useTranslation();

  const [formStep, setFormStep] = useState(0);
  const [changeDetailModal, setChangeDetailModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const MODAL_VIEWS = {
    CHANGE_ETA: "change-eta",
    CHANGE_REASON_FOR_STAY: "change-reason-for-stay",
    CHANGE_GUEST_INFO: "change-guest-info",
  };

  const initialArrivalTime =
    getInitialArrivalTime(propertyContent)(upcomingTrip);

  const [arrivalDetailsValues, setArrivalDetailsValues] = useState({
    arrivalTime: initialArrivalTime,
    reasonForStay: {
      vacation: false,
      familyVacation: false,
      staycation: false,
      sightseeing: false,
      specialEvent: false,
      birthday: false,
      anniversary: false,
      wedding: false,
      honeymoon: false,
      babymoon: false,
      conference: false,
      tradeShow: false,
      businessMeeting: false,
      workcation: false,
      corporateRetreats: false,
      specialNotes: "",
    },
    guestInfo: {
      guest2FirstName: "",
      guest2LastName: "",
      guest3FirstName: "",
      guest3Age: null,
    },
    showCards: false,
  });

  const handleCheckboxChange = (evt) => {
    const { name, checked } = evt.target;

    const checkedCount = Object.values(
      arrivalDetailsValues.reasonForStay
    ).filter((value) => value === true).length;

    // Only 3 checkboxes can be checked at a time
    if ((checked && checkedCount < 3) || !checked) {
      setArrivalDetailsValues((prev) => {
        return {
          ...prev,
          reasonForStay: {
            ...prev.reasonForStay,
            [name]: checked,
          },
        };
      });
    }
  };

  // TODO: This should be fetching real values, if it finds any then show the cards??
  const hasArrivalDetails = arrivalDetailsValues.showCards;
  // const hasArrivalDetails = [
  //   bookingArrivalTime,
  //   bookingreasonForStay,
  //   bookingGuestInfo,
  // ].some((a) => a);

  const goToFormStep = (step) => {
    setFormStep(step);
  };

  const checkInTimeFormatted = formatArrivalTime(
    arrivalDetailsValues.arrivalTime
  );

  const onArrivalDetailsSubmit = (guestInfo) => {
    setIsLoading(true);
    // TODO: This is a real call but the other 2 forms aren't being submitted
    changeArrivalTime({ arrivalTime: arrivalDetailsValues.arrivalTime });
    setArrivalDetailsValues((prev) => ({
      ...prev,
      guestInfo,
      showCards: true, // TODO: REMOVE THIS ONCE INTEGRATED, maybe?
    }));
    setIsLoading(false);
  };

  if (isLoading) {
    return <BookingFlowLoadingIndicator />;
  }

  if (hasArrivalDetails) {
    return (
      <>
        {changeDetailModal && (
          <Modal
            className="new-modal--change-detail"
            closeModal={() => setChangeDetailModal(null)}
          >
            {changeDetailModal === MODAL_VIEWS.CHANGE_ETA && (
              <ChangeETA
                // TODO:
                // bookingArrivalTime={bookingArrivalTime}
                changeArrivalTime={changeArrivalTime}
                upcomingTrip={upcomingTrip}
                propertyContent={propertyContent}
                goBackHandler={() => setChangeDetailModal(null)}
              />
            )}
            {changeDetailModal === MODAL_VIEWS.CHANGE_REASON_FOR_STAY && (
              <ChangeReasonForStay
                // TODO:
                // bookingReasonsForStay={bookingReasonsForStay}
                // editReasonsForStay={editReasonsForStay}
                goBackHandler={() => setChangeDetailModal(null)}
              />
            )}
            {changeDetailModal === MODAL_VIEWS.CHANGE_GUEST_INFO && (
              <ChangeGuestInfo
                // TODO:
                // bookingGuestInfo={bookingGuestInfo}
                // editGuestInfo={editGuestInfo}
                upcomingTrip={upcomingTrip}
                propertyContent={propertyContent}
                goBackHandler={() => setChangeDetailModal(null)}
              />
            )}
          </Modal>
        )}

        <div className="fs-trip__arrival-details">
          <h3 className="fs-trip__arrival-details__cards-title ty-h3">
            {t("Arrival Details")}
          </h3>
          <div className="fs-trip__arrival-details__cards">
            <ArrivalDetailCard
              icon="/images/profiles_2018/flight-arrival.svg"
              name="Arrival Time"
              value={checkInTimeFormatted}
              onClick={() => setChangeDetailModal(MODAL_VIEWS.CHANGE_ETA)}
            />
            <ArrivalDetailCard
              icon="/images/profiles_2018/beach-icon.svg"
              name="Reason For Stay"
              value="Birthday, Other, Other, Other, Other"
              onClick={() =>
                setChangeDetailModal(MODAL_VIEWS.CHANGE_REASON_FOR_STAY)
              }
            />
            <ArrivalDetailCard
              icon="/images/profiles_2018/occupancy-icon.svg"
              name="Guest Info"
              value={null}
              onClick={() =>
                setChangeDetailModal(MODAL_VIEWS.CHANGE_GUEST_INFO)
              }
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="fs-trip__arrival-details">
      <div className="fs-trip__arrival-details__container">
        <h2 className="fs-trip__arrival-details__title">Arrival Details</h2>

        {formStep === 0 && (
          <TimeOfArrival
            checkInTimeFormatted={checkInTimeFormatted}
            arrivalTimeValue={arrivalDetailsValues.arrivalTime}
            setArrivalDetailsValues={setArrivalDetailsValues}
            goToFormStep={goToFormStep}
          />
        )}
        {formStep === 1 && (
          <>
            <p className="fs-trip__arrival-details__subtitle">
              {t(
                "Celebrating a special occasion? We will gladly assist with preparing a special surprise for your companion or family member."
              )}
            </p>
            <ReasonForStay
              reasonForStayValues={arrivalDetailsValues.reasonForStay}
              onChange={handleCheckboxChange}
              onGoBack={() => goToFormStep(0)}
              onSubmit={() => goToFormStep(2)}
            />
          </>
        )}
        {formStep === 2 && (
          <>
            <p className="fs-trip__arrival-details__subtitle">
              {t("Please provide all guest information.")}
            </p>
            <GuestInformation
              upcomingTrip={upcomingTrip}
              propertyContent={propertyContent}
              guestInfoValues={arrivalDetailsValues.guestInfo}
              onGoBack={() => goToFormStep(1)}
              onSubmit={onArrivalDetailsSubmit}
            />
          </>
        )}

        <div className="fs-trip__arrival-details__page-num">
          {`${formStep + 1} / 3`}
        </div>
      </div>
    </div>
  );
}
