import React from "react";
import get from "lodash/get";

import { useTranslation } from "hooks";
import BookingFlowLoadingIndicator from "BookingFlow/components/BookingFlowLoadingIndicator";
import { GuestInformation } from ".";

export default function ChangeGuestInfo({ upcomingTrip, propertyContent }) {
  const { t } = useTranslation();

  const { firstName, surname } = get(upcomingTrip, ["contactDetails", "name"]);

  const isLoading = false;
  return (
    <>
      {isLoading && <BookingFlowLoadingIndicator />}
      {!isLoading && (
        <div className="change-detail-modal">
          <div className="change-detail-modal__header">
            <div className="change-detail-modal__title">
              {t("Guest Information")}
            </div>
            <p className="change-detail-modal__copy">
              {t("Please confirm your attending guests")}
            </p>
          </div>
          <GuestInformation
            upcomingTrip={upcomingTrip}
            propertyContent={propertyContent}
            holderName={`${firstName}, ${surname}`}
            onSubmit={() => {
              console.log("Update guest info for real");
            }}
          />
        </div>
      )}
    </>
  );
}
