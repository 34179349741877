import React from "react";

import { useTranslation } from "hooks";
import useArrivalTime from "BookingFlow/PersonaliseYourStay/hooks/useArrivalTime";
import classNames from "classnames";
import Dropdown from "./Dropdown";

export default React.forwardRef(
  ({ value, onChange, disabled, label, labelAll = [], error }, _ref) => {
    const { t } = useTranslation();

    const {
      hour,
      minute,
      meridiem,
      handleChangeHour,
      handleChangeMinute,
      handleChangeMeridiem,
      HOURS,
      MINUTES,
      MERIDIEMS,
    } = useArrivalTime({ value, onChange });

    const hourValue = `${["12", "00"].includes(hour) ? 12 : hour % 12}`;

    return (
      <div
        className={classNames("arrival-time", {
          "arrival-time-error": !!error,
        })}
      >
        <div className="arrival-time-selects">
          <Dropdown
            name="checkInTimeHour"
            label={label || labelAll[0] || t("ETA")}
            identifier="checkInTimeHour"
            value={hourValue}
            onChange={handleChangeHour}
            options={HOURS}
            disabled={disabled}
            required
            error={!!error}
            aria-label={t("Arrival Time Hour")}
          />
          <Dropdown
            name="checkInTimeMinutes"
            label={labelAll[1]}
            identifier="checkInTimeMinutes"
            value={`${minute}`}
            onChange={handleChangeMinute}
            options={MINUTES}
            disabled={disabled}
            required
            error={!!error}
            aria-label={t("Arrival Time Minute")}
          />
          <Dropdown
            name="checkInTimePeriod"
            label={labelAll[2]}
            identifier="checkInTimePeriod"
            value={meridiem}
            onChange={handleChangeMeridiem}
            options={MERIDIEMS}
            disabled={disabled}
            required
            error={!!error}
            aria-label={t("Arrival Time AM/PM")}
          />
        </div>
        {error && (
          <div className="error-message">
            <span className="formElement-message" role="alert">
              {error}
            </span>
          </div>
        )}
      </div>
    );
  }
);
