import React from "react";
import { useForm, Controller } from "react-hook-form";

import { ArrivalTimeSelects } from "Profile/components";
import { useTranslation } from "hooks";
import { SignInModal } from "Authentication";

export default function TimeOfArrival({
  checkInTimeFormatted,
  arrivalTimeValue,
  setArrivalDetailsValues,
  goToFormStep,
}) {
  const { t } = useTranslation();

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      arrivalTime: arrivalTimeValue,
    },
  });

  const formValuesArrivalTime = watch("arrivalTime");

  const onSubmit = ({ arrivalTime }) => {
    setArrivalDetailsValues((prev) => ({
      ...prev,
      arrivalTime,
    }));

    goToFormStep(1);
  };

  return (
    <>
      <p className="fs-trip__arrival-details__subtitle">
        {t("To prepare your room, what is your estimated time of arrival?")}
      </p>
      <form
        className="fs-trip__arrival-details__eta-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="arrivalTime"
          control={control}
          as={ArrivalTimeSelects}
          labelAll={[t("Hour"), t("Min"), t("AM/PM")]}
          disabled={false}
          rules={{ required: true }}
        />
        <div className="fs-trip__arrival-details__btns">
          <SignInModal
            id="time-of-arrival-next"
            onClick={() => onSubmit({ arrivalTime: formValuesArrivalTime })}
            buttonLabel={t("Next")}
            type="CTA--submit"
            kind="primary"
            showToastOnNextView
            aria-label={t("Set {0} arrival time", [checkInTimeFormatted])}
          />
        </div>
      </form>
    </>
  );
}
