import React, {
  useLayoutEffect,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_TYPES } from "api/tretail/authentication/generateOTP";
import { useDispatchWithLocale, useTranslation } from "hooks";
import Modal from "BookingFlow/components/Modal";
import { Button, Link } from "Profile/components";

import {
  selectIsUserPersistent,
  selectLoginEmail,
  selectLoginPhoneNumber,
} from "store/profile";
import {
  generateOtpReset,
  setActiveSignInModal,
  selectActiveSignInModal,
} from "store/signIn";
import ThemeContextProvider, { ThemeContext } from "contexts/ThemeContext";
import { selectShouldOpen } from "store/toastNotification";
import OtpForm from "./components/OtpForm";

export default function SignInModal({
  id,
  email = "",
  phoneNumber = "",
  buttonLabel = "Sign In",
  ariaLabel,
  type = "CTA",
  kind = "primary",
  className,
  afterSignInRedirectTo,
  href,
  onClick = () => {},
  showToastOnNextView = false,
  fromAnonymous = false,
  children,
}) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const dispatchWithLocale = useDispatchWithLocale();

  const isUserPersistent = useSelector(selectIsUserPersistent);
  const loginEmail = useSelector(selectLoginEmail);
  const loginPhoneNumber = useSelector(selectLoginPhoneNumber);
  const [signInExpired, setSignInExpired] = useState(isUserPersistent);
  const [isOpen, setIsOpen] = useState(false);
  const hasOpened = useRef(false);
  const handleSuccess = useRef();
  const toastShouldOpen = useSelector(selectShouldOpen);
  const activeSignInModal = useSelector(selectActiveSignInModal);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (e) => {
    e?.preventDefault();
    dispatch(setActiveSignInModal(id));
    handleSuccess.current = () => {
      dispatchWithLocale(generateOtpReset());
      dispatch(setActiveSignInModal(undefined));
      closeModal();
      onClick(e);
    };
    if (!hasOpened.current) {
      dispatchWithLocale(generateOtpReset());
    }
    hasOpened.current = true;
    setIsOpen(true);
  };

  const handleClick = (e) => {
    if (isUserPersistent || fromAnonymous) {
      setSignInExpired(true);
      openModal(e);
    } else {
      onClick(e);
    }
  };

  useEffect(() => {
    setSignInExpired(isUserPersistent);
  }, [isUserPersistent]);

  useLayoutEffect(() => {
    if (
      activeSignInModal === id &&
      handleSuccess.current &&
      !afterSignInRedirectTo &&
      toastShouldOpen
    ) {
      handleSuccess.current();
    }
  }, [toastShouldOpen]);

  return (
    <>
      <ThemeContextProvider theme="light">
        {isOpen && (
          <Modal className="new-modal--sign-in" closeModal={closeModal}>
            <OtpForm
              signInTitle={t("Sign In To Continue")}
              introContent={t("Please sign in and verify your identity.")}
              afterSignInRedirectTo={afterSignInRedirectTo}
              requestOtpInitialValues={{
                sendCodeTo: {
                  [LOGIN_TYPES.PHONE_NUMBER]: loginPhoneNumber || phoneNumber,
                  [LOGIN_TYPES.EMAIL_ADDRESS]: loginEmail || email,
                },
                sendCodeVia: LOGIN_TYPES.EMAIL_ADDRESS,
              }}
              isUserPersistent={!fromAnonymous}
              showToastOnNextView={showToastOnNextView}
              disablePersistentLoginOption={fromAnonymous}
            />
          </Modal>
        )}
      </ThemeContextProvider>
      <ThemeContextProvider theme={theme}>
        {type === "CTA" && (
          <Button
            className={className}
            onClick={signInExpired ? openModal : handleClick}
            aria-label={ariaLabel || buttonLabel}
            kind={kind}
          >
            {children || t(buttonLabel)}
          </Button>
        )}
        {type === "CTA--submit" && (
          <Button
            className={className}
            type={signInExpired ? "button" : "submit"}
            onClick={signInExpired ? openModal : undefined}
            aria-label={ariaLabel || buttonLabel}
            kind={kind}
          >
            {children || t(buttonLabel)}
          </Button>
        )}
        {type === "Link" && (
          <Link
            to={signInExpired ? null : afterSignInRedirectTo}
            className={className}
            onClick={signInExpired ? openModal : handleClick}
            href={signInExpired ? afterSignInRedirectTo : href}
            aria-label={ariaLabel || buttonLabel}
            kind={kind}
          >
            {children || t(buttonLabel)}
          </Link>
        )}
        {type === "CustomButton" && (
          <button
            className={className}
            onClick={signInExpired ? openModal : handleClick}
            aria-label={ariaLabel || buttonLabel}
            type="button"
          >
            {children || t(buttonLabel)}
          </button>
        )}
      </ThemeContextProvider>
    </>
  );
}
