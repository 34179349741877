import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Checkbox } from "Profile/components";
import { useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import ToggleBlock from "Profile/components/TransportationAmenities/ToggleBlock";

export default function ReasonForStay({
  reasonForStayValues,
  onChange,
  onGoBack,
  onSubmit,
}) {
  const { t } = useTranslation();

  const media = useContext(MediaContext);

  const [toggleBlockState, setToggleBlockState] = useState(null);

  const { register, handleSubmit } = useForm({
    defaultValues: reasonForStayValues,
  });

  const allCheckboxes = {
    leisure: [
      {
        name: "vacation",
        label: "Vacation",
        value: "Vacation",
        defaultValue: false,
      },
      {
        name: "familyVacation",
        label: "Family Vacation",
        value: "Family Vacation",
        defaultValue: false,
      },
      {
        name: "staycation",
        label: "Staycation",
        value: "Staycation",
        defaultValue: false,
      },
      {
        name: "sightseeing",
        label: "Sightseeing",
        value: "Sightseeing",
        defaultValue: false,
      },
      {
        name: "specialEvent",
        label: "Special Event",
        value: "Special Event",
        defaultValue: false,
      },
    ],
    celebration: [
      {
        name: "birthday",
        label: "Birthday",
        value: "Birthday",
        defaultValue: false,
      },
      {
        name: "anniversary",
        label: "Anniversary",
        value: "Anniversary",
        defaultValue: false,
      },
      {
        name: "wedding",
        label: "Wedding",
        value: "Wedding",
        defaultValue: false,
      },
      {
        name: "honeymoon",
        label: "Honeymoon",
        value: "Honeymoon",
        defaultValue: false,
      },
      {
        name: "babymoon",
        label: "Babymoon",
        value: "Babymoon",
        defaultValue: false,
      },
    ],
    business: [
      {
        name: "conference",
        label: "Conference",
        value: "Conference",
        defaultValue: false,
      },
      {
        name: "tradeShow",
        label: "Trade Show",
        value: "Trade Show",
        defaultValue: false,
      },
      {
        name: "businessMeeting",
        label: "Business Meeting",
        value: "Business Meeting",
        defaultValue: false,
      },
      {
        name: "workcation",
        label: "Workcation",
        value: "Workcation",
        defaultValue: false,
      },
      {
        name: "corporateRetreats",
        label: "Corporate Retreats",
        value: "Corporate Retreats",
        defaultValue: false,
      },
    ],
  };

  const checkboxCategoriesIcons = {
    leisure: "/images/profiles_2018/beach-icon.svg",
    celebration: "/images/profiles_2018/wedding-icon.svg",
    business: "/images/profiles_2018/business-laptop-icon.svg",
  };

  const checkedCount = Object.values(reasonForStayValues).filter(
    (value) => value === true
  ).length;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="fs-trip__arrival-details__occasion-form"
    >
      {media.isLessThan.scssLg ? (
        ["leisure", "celebration", "business"].map((title) => {
          return (
            <ToggleBlock
              key={title}
              title={
                <div className="arrival-amenities__category-title">
                  <h4 className="ty-c1">{t(title)}</h4>
                </div>
              }
              groupControlledToggle={toggleBlockState === title}
              groupController={(value) =>
                setToggleBlockState(value ? title : null)
              }
              iconStyle="solid"
            >
              <div className="fs-trip__arrival-details__occasion-form__checkboxes">
                {allCheckboxes[title].map(({ name, label }) => {
                  return (
                    <Checkbox
                      key={name}
                      name={name}
                      checked={reasonForStayValues[name]}
                      label={label}
                      onChange={onChange}
                    >
                      {t(label)}
                    </Checkbox>
                  );
                })}
              </div>
            </ToggleBlock>
          );
        })
      ) : (
        <>
          <div className="fs-trip__arrival-details__occasion-form__checkboxes">
            {["leisure", "celebration", "business"].map((title) => {
              return (
                <div
                  key={title}
                  className="fs-trip__arrival-details__occasion-form__checkboxes__col"
                >
                  <div className="fs-trip__arrival-details__occasion-form__checkboxes__col__header ty-c3">
                    <img src={checkboxCategoriesIcons[title]} alt="" />
                    {t(title)}
                  </div>
                  {allCheckboxes[title].map(({ name, label }) => {
                    return (
                      <Checkbox
                        key={name}
                        name={name}
                        checked={reasonForStayValues[name]}
                        label={label}
                        onChange={onChange}
                      >
                        {t(label)}
                      </Checkbox>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}

      {((media.isLessThan.scssLg && checkedCount > 0) ||
        media.isGreaterThan.scssLg) && (
        <div className="fs-trip__arrival-details__occasion-form__textarea">
          <label htmlFor="specialNotes" className="ty-c4">
            {t("Special Notes")}
          </label>
          <textarea
            className="fs-trip__text_area"
            name="specialNotes"
            placeholder={t(
              "Let us know of more details and we can arrange a special arrival for the occasion. "
            )}
            ref={register()}
          />
        </div>
      )}

      <div className="fs-trip__arrival-details__btns">
        {onGoBack ? (
          <>
            <Button
              type="button"
              kind="secondary"
              onClick={onGoBack}
              aria-label=""
            >
              {t("Back")}
            </Button>
            <Button
              type="submit"
              className="CTA--secondary--dark"
              aria-label=""
            >
              {t("Next")}
            </Button>
          </>
        ) : (
          <Button type="submit" className="CTA--secondary--dark" aria-label="">
            {t("Update")}
          </Button>
        )}
      </div>
    </form>
  );
}
