import React from "react";
import get from "lodash/get";

import { useForm } from "react-hook-form";
import { Button, Dropdown, TextInput } from "Profile/components";
import { useTranslation } from "hooks";

export default function GuestInformation({
  upcomingTrip,
  propertyContent,
  guestInfoValues,
  onGoBack,
  onSubmit,
}) {
  const { t } = useTranslation();

  const { handleSubmit, register } = useForm({
    defaultValues: guestInfoValues,
  });

  const { firstName, surname } = get(upcomingTrip, ["contactDetails", "name"]);

  const { numberAdults, numberChildren } = get(upcomingTrip, [
    "hotelProducts",
    0,
    "guestCount",
  ]);

  const minChildAge = parseInt(propertyContent.minChildAge || 0, 10);
  const maxChildAge = parseInt(propertyContent.maxChildAge || "16", 10);

  const childAges = Array.from(
    { length: maxChildAge - minChildAge + 1 },
    (_, i) => minChildAge + i
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="fs-trip__arrival-details__guest-form"
    >
      <div>
        <div className="ty-c1">{t("Guest 1 | Reservation holder")}</div>
        <div className="fs-trip__arrival-details__guest-form__holder-name">
          {`${firstName}, ${surname}`}
        </div>
      </div>

      {Array.from({ length: numberAdults }, (_, index) => index)
        .map((n) => {
          const guestNumber = 2 + n;
          return (
            <div key={`guestAdult-${n}`}>
              <div className="ty-c1">{t(`Guest ${guestNumber} | Adult`)}</div>
              <div className="fs-trip__arrival-details__guest-form__guest-fields">
                <TextInput
                  name={`guest${guestNumber}FirstName`}
                  placeholder={t("First Name")}
                  register={register()}
                />
                <TextInput
                  name={`guest${guestNumber}LastName`}
                  placeholder={t("Last Name")}
                  register={register()}
                />
              </div>
            </div>
          );
        })
        .map((inputs) => inputs)}

      {Array.from({ length: numberChildren }, (_, index) => index)
        .map((n) => {
          const guestNumber = 2 + numberAdults + n;
          return (
            <div key={`guestChild-${n}`}>
              <div className="ty-c1">{t(`Guest ${guestNumber} | Child`)}</div>
              <div className="fs-trip__arrival-details__guest-form__guest-fields">
                <TextInput
                  name={`guest${guestNumber}FirstName`}
                  placeholder={t("First Name")}
                  register={register()}
                />
                <Dropdown
                  name={`guest${guestNumber}Age`}
                  label={t("Age")}
                  options={childAges}
                  register={register()}
                />
              </div>
            </div>
          );
        })
        .map((inputs) => inputs)}

      <div className="fs-trip__arrival-details__btns">
        {onGoBack ? (
          <>
            <Button
              type="button"
              kind="secondary"
              onClick={onGoBack}
              aria-label=""
            >
              {t("Back")}
            </Button>
            <Button
              type="submit"
              className="CTA--secondary--dark"
              aria-label=""
            >
              {t("Save")}
            </Button>
          </>
        ) : (
          <Button type="submit" className="CTA--secondary--dark" aria-label="">
            {t("Update")}
          </Button>
        )}
      </div>
    </form>
  );
}
